/* 
	holds all of the custom styling for the site 
*/

/* 
	Import bootstrap-sass so that we have access to all of its selectors 
*/
/*@import "bootstrap";*/

/*
	Brand colour scheme	
*/
$brand-colour-1: #DC3522;
$brand-colour-2: #D9CB9E;
$brand-colour-3: #374140;
$brand-colour-4: #2A2C2B;
$brand-colour-5: #1E1E20;

@font-face {
	font-family: "HeroLight";
	src: url("../fonts/Hero Light.otf");
}

body {
	padding-top: 10px !important;
	font-family: HeroLight, Helvetica, Verdana, Sans-Serif;
	font-size: 20px;
	width: 100%;
	overflow-x: hidden;
}

nav {

	.navbar-header{
		margin-left: 10%;
	}
	.navbar-toggle {
		border-color: $brand-colour-5 !important;
		background-color: $brand-colour-5 !important;
	}

	/*
		Overrides bootstrap's default values
	*/
	left: 0;
	right: 0;
	/*
		stops the browser from adding a single pixel to the bottom when the size of the content is the exact
		same size as the container
	*/
	@media (min-width: 1024px) {
		min-height: 50px;
		max-height: 50px;	
	}

	#navbar.navbar-collapse[aria-expanded="true"] {
		/*
			Theme the drop down menu to match the theme - still needs reworking as it is still not functioning
			correctly!
		*/
		background-color: $brand-colour-5 !important;
		/*li {
			&.active > a.navbar-brand {
    			border: 3px solid $brand-colour-5 !important;
			}
		}*/
	}

	.navbar-nav {
		float: right;

		/*
		-webkit-transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000); 
		-moz-transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000); 
	    -o-transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000); 
	    transition: all 500ms cubic-bezier(0.420, 0.000, 0.580, 1.000); /* ease-in-out */

	    li {
	    	&.active > a.navbar-brand {
				background-color: $brand-colour-5 !important;
				color: $brand-colour-1 !important;
				text-decoration: underline !important;

				&:hover {
					background-color: $brand-colour-5 !important;
	    			color: white !important;
	    			text-decoration: underline !important;
	    		}
	    	}

	    	/*
	    		When the user hovers over the logo it should change both background and text colour
		    		--> This doesn't currently work for mobile nav menu's which is likely to do with a 
			    		change in class name that causes issues
	    	*/
	    	& > a.navbar-brand:hover {
				background-color: $brand-colour-5;
    			color: $brand-colour-1;
				text-decoration: underline;
	    	}

	    	&:last-of-type {
				margin-right: 25px;
			}
		}
	}

	img.navbar-logo {
		height: 50px;
		width: 100px;

		@media (min-width: 400px) and (max-width: 480px) {
			margin-top: 8px;
		}
	}
}

.alignment-resolve {
	/*
		Overrides both margins-left/right from the default bootstrap.css values of -15px/15px respectively for the 
		.row class, this looks a little more aesthetically pleasing in my opinion as the original margins had the 
		content far too close to the edges of the screen for my liking
	*/
	margin-right: 50px;
	margin-left: 50px;

	@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
		margin-right: 10px;
		margin-left: 10px;
	}
}

.container, .container-fluid {
	min-width: 100%;
}

.jumbotron {
	padding-top: 40px;
	width: auto;
	overflow-x: hidden;

	text-align: center;
	padding-bottom: 0px !important;
	margin-bottom: 0px !important;
}

section {
	min-height: 600px;
	width: 100%;

	&:nth-child(odd) {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-right: -15px;
	}

	/* all even children will have a background colour of $brand-colour-3 and have their text set to white */
	&:nth-child(even) {
		padding-top: 50px;
		padding-bottom: 50px;
		margin-right: -15px;
		background-color: $brand-colour-3;
		color: #fff;
	}

	&#welcome {

		img.welcome-logo {
			width: 500px;
			height: 250px;
			margin-bottom: 50px;

			@media (min-width: 300px) and (max-width: 399px) {
				margin-left: 0 !important;
				width: 300px;
				height: 140px;
			}

			@media (min-width: 400px) and (max-width: 480px) {
				margin-left: 0 !important;
				float: left;
	    		width: 400px;
	    		height: 200px;
		    }
		}
	}

	&#portfolio {
		.showcase {
			display: block;
			height: auto;
			min-height: 450px;
			margin: 25px 0 25px 0 !important;

			padding-left: 0px !important;
			@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
				margin-left: 0px !important;
			} // iPhone 5 & 5S 
			@media (min-width: 400px) and (max-width: 480px) {
				padding-left: 42px !important;
			}
			@media (min-width: 715px) {
				padding-left: 50px !important;
			}
			/*padding-right: 25px !important;*/
		}
	}

	&#about {

		.skill-list {
			font-weight: bold;
			color: $brand-colour-1;
			text-align: center;

			p {
				font-size: 30px !important;
			}
		}
	}

	&#services {

		padding-bottom: 100px;

		.services-list {
			display: block;
			height: auto;
			min-height: 450px;
			margin: 25px 0 25px 0 !important;

			padding-left: 0px !important;
			@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
				margin-left: 0px !important;
			} // iPhone 5 & 5S
			@media (min-width: 400px) and (max-width: 480px) {
				padding-left: 42px !important;
			}
			@media (min-width: 715px) {
				padding-left: 0px !important;
			}
			/*padding-right: 25px !important;*/
		}
	}

	&#contact {

		min-height: 900px;
		clear: both;

		.row {
			margin-top: 0.75em;
		}

		.contact-forms {
			.col-md-12 {
				padding-right: 50px !important;
				padding-left: 0px !important;
			}

			@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
				font-size: 18px;
			}
		}

		.contact-tag {
			text-align: right;
			
			@media (min-width: 300px) and (max-width: 480px) {
				/*@extend .col-xs-6;*/
				margin-right: -15px !important;
			}
		}
		.contact-form {
			text-align: left;
			
			@media (min-width: 300px) and (max-width: 480px) {
				/*@extend .col-xs-6;*/
				padding-right: 1px !important;
			}
		}

		p:last-of-type {
			margin-top: 20px;
		}
	}
}

.services-group, .portfolio-group {

	&:first {
		//margin-left: 5%;

		@media (min-width: 1200px) {
			//margin-left: 7.5% !important;
		}
	}

	& {
		margin-right: 1% !important;
	}

    float: left;
    border: 3px solid $brand-colour-5;
    width: 300px;
    height: 475px;
    padding: 10px;
    margin-bottom: 25px;
	overflow: hidden;

	&:hover {
		border: 3px solid $brand-colour-1;
	}

    .group-header {
		
		text-align: center;
		padding: 15px 0 15px 0;

    	h1, h2, h3, h4, h5, h6, p {
    		margin-top: 5px !important;
    		margin-bottom: 5px !important;
    		font-size: 30px !important;
    		font-weight: bold;
    	}
    	color: $brand-colour-1;
    	width: 100%;
    }

    /*
    	Group-image should typically be a thumbnail of the site in a 225x225 image format to allow for smaller screen sizes, likely to be .png as I hate the
    	compression trade-off for the quality and increased page load times, which in this day and age even on mobile devices
    	is negligable, unless you live way out in the boonies! 

    	!!!!!! --> May adjust image and group size based on @media queries at a later date
    */
    .group-image {
    	position: relative;
    	min-height: 225px;
    	min-height: 225px;
    	max-height: 225px;
    	max-width: 225px;
    	margin: 0 25px 0 25px;
    	font-size: 4em !important;

    	@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
			font-size: 2em !important;
		} // iPhone 5 & 5S 
		@media (min-width: 400px) and (max-width: 480px) {
			font-size: 3em !important;
		}
		@media (min-width: 715px) {
			font-size: 4em !important;
		}
    }

    .group-footer {
    	position: relative;
    	text-align: center;
    	padding: 10px 10px 10px 10px;
    	color: #fff;
    	width: 100%;
    	p {
    		/* 
	    		Set font size smaller as otherwise I can't fit within the boundaries of the box 
	    		this may later be changed using @media queries when I chose to change the size of the box relative
	    		to the screen size but for the time being 14px seems a reasonable size to use, however, it may be a
	    		little hard to read for anyone with poor eye sight
    		*/
    		font-size: 14px;
    	}
    }
}

footer {
	/*margin-right: -15px;*/
	background-color: $brand-colour-5;

	@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
		height: 200px;
		min-height: 200px;
	} // iPhone 5 & 5S 
	@media (min-width: 400px) and (max-width: 480px) {
		height: 200px;
		min-height: 200px;
	}
	height: 125px;
	min-height: 125px;
	overflow: auto;
	min-width: 100%;


	.container-fluid {
		margin-top: 2em;
		margin-bottom: 2em;

		.footer-left {
			color: $brand-colour-1;
			float: left;
		}

		.divider {
			border-left: 2px solid $brand-colour-1;
		}

		.footer-right {
			color: $brand-colour-1;

			&:hover {
				.social-media-icon {
					//color: #000;
				}
			}
		}
	}
}

.social-media-icon {
	max-height: 50px;
	max-width: 50px;
	min-height: 50px;
	min-width: 50px;

	padding-top: 5px;
	text-decoration: none !important;

	color: #fff;

	&:visited {
		color: #fff;
		text-decoration: none !important;
	}

	&:focus, &:hover {
		color: #fff;
		text-decoration: none !important;
		background-color: $brand-colour-1;
	}
}

/*
	Need to work on the colours as they are hard to read at times and would like to change the font colour based on .hover and
	a few other parameters
*/
.btn-branded {
	background-color: $brand-colour-5;
	border-color: #fff;
	color: $brand-colour-1;

	&:hover {
		background-color: $brand-colour-5;
		border-color: $brand-colour-1;
		color: #fff;
	}
}

.brand-box-link {
	display: block;
	min-height: 231px;
	min-width: 231px;
	height: 231px;
	width: 231px;

	&:hover { 
		border: 3px solid $brand-colour-1;
	}
}

.brand-text-colour-1 {
	color: $brand-colour-1;
	a:visited {
		text-decoration: none;
	}
	a:focus, a:hover {
		color: $brand-colour-1;
		text-decoration: underline;
	}
}
.brand-text-colour-2 {
	color: $brand-colour-2;
}
.brand-text-colour-3 {
	color: $brand-colour-3;
}
.brand-text-colour-4 {
	color: $brand-colour-4;
}
.brand-text-colour-5 {
	color: $brand-colour-5;
}
